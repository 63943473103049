/* Основной стиль для .cards */
.cards {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr)); /* На больших экранах 1 колонка с минимальной шириной 600px */
    padding: 10px;
}

/* Стиль для .card */
.card {
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    margin: 20px 0;
}

.card-center {
    text-align: center;
}

.card-border {
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
    .cards {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Используем минимальную ширину 300px для карточек */
        padding: 10px; /* Можно уменьшить паддинг для мобильных */
        margin-top: 60px;  /* Добавляем отступ сверху, чтобы меню не перекрывало контент */
        margin-bottom: 60px;
    }

    .card {
        padding: 12px; /* Уменьшаем паддинг для мобильных устройств */
    }
}
