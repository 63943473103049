.header {
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
}

@media (max-width: 768px) {
    .header {
        margin-top: 40px;
    }
}