.container {
    padding: 20px;
    width: 92%;
    margin: auto;
    font-family: Arial, sans-serif;
}

h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.input-field, .textarea-field {
    width: 98%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.textarea-field {
    height: 100px;
    resize: vertical;
}

.submit-button {
    width: auto; /* Кнопка займет всю ширину контейнера */
    padding: 10px 20px;
    background-color: #007BFF;
    color: #FFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    display: block;
}

.submit-button:hover {
    background-color: #0056b3;
}

@media screen and (max-width: 768px) {
    .input-field, .textarea-field {
        width: 93%;
    }
}

