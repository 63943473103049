@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%); /* Начинаем слева за пределами видимой области */
        opacity: 0; /* Полностью прозрачное */
    }
    100% {
        transform: translateX(0); /* Позиция по умолчанию */
        opacity: 1; /* Полностью видимое */
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(100%); /* Начинаем ниже видимой области */
        opacity: 0; /* Полностью прозрачное */
    }
    100% {
        transform: translateY(0); /* Позиция по умолчанию */
        opacity: 1; /* Полностью видимое */
    }
}

.image-avatar {
    animation: slideInFromLeft 1s ease forwards;
    border-radius: 50%; /* Делает изображение круглым */
    object-fit: cover; /* Сохраняет пропорции изображения */
}

.content-image {
    text-align: center;
}

.content-text h1 {
    text-align: center;
}

.content-text a {
    text-align: center;
}

.content-text {
    animation: slideIn 1s ease forwards;
    padding-bottom: 25px;
    padding-top: 55px;
}