/* Стиль для бургер-меню */
.burger {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 4px;
    position: fixed; /* Используем фиксированное позиционирование */
    top: 20px;  /* Отступ сверху */
    right: 20px; /* Отступ справа */
    z-index: 10; /* Убедимся, что бургер-меню будет поверх других элементов */
}

.burger .bar {
    width: 30px;
    height: 4px;
    background-color: #333;
    border-radius: 2px;
}

/* Основной стиль для nav */
nav {
    display: flex;
    justify-content: center;  /* Центрируем навигацию */
    align-items: center;
    padding: 10px;
    position: relative;
    z-index: 5; /* Понижаем z-index для nav */
    width: 100%; /* Гарантируем, что контейнер nav будет занимать всю ширину */
    box-sizing: border-box; /* Убедимся, что padding не выходит за пределы */
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
    justify-content: center; /* Центрируем элементы списка */
    width: 100%; /* Чтобы меню занимало всю ширину */
}

li {
    display: inline-block;
    position: relative;
}

li a {
    display: block;
    color: #212529;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: black;
    transition: width 0.3s;
    position: absolute;
    bottom: 0;
}

li a:hover:not(.active)::after {
    width: 100%;
    left: 0;
}

li a.active {
    color: white;
    background-color: #4CAF50;
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
    /* Скрываем пункты меню по умолчанию на мобильных */
    ul {
        display: none;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 60px; /* Отступ от верхней части экрана */
        right: 0; /* Размещаем меню по правому краю */
        background-color: white;
        z-index: 5; /* Чтобы оно не перекрывало бургер-меню */
        margin: 0; /* Убираем отступы, чтобы меню не заезжало за края */
        padding: 0; /* Убираем внутренние отступы */
    }

    ul.open {
        display: flex; /* Показываем меню, когда оно открыто */
    }

    /* Позиционируем бургер-меню в правом верхнем углу */
    .burger {
        display: flex;
        top: 20px;  /* Добавляем отступ сверху */
        right: 20px; /* Добавляем отступ справа */
    }
}
